import React from 'react';
import {IProduct} from '../../../../types/galleryTypes';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import s from './ProductPrice.scss';

export enum DataHook {
  SrPriceBeforeDiscount = 'sr-product-item-price-before-discount',
  SrPriceToPay = 'sr-product-item-price-to-pay',
  OutOfStock = 'product-item-out-of-stock',
  PriceBeforeDiscount = 'product-item-price-before-discount',
  PriceToPay = 'product-item-price-to-pay',
}

export interface IProductPriceProps {
  product: IProduct;
  allowFreeProducts: boolean;
  textsMap: {
    productPriceBeforeDiscountSR: string;
    productOutOfStockText: string;
    productPriceAfterDiscountSR: string;
    productPriceWhenThereIsNoDiscountSR: string;
  };
  isAddtoCartButtonExists: boolean;
}

const getPriceBeforeDiscount = ({textsMap, product}) => (
  <>
    <span className={a11y.srOnly} data-hook={DataHook.SrPriceBeforeDiscount}>
      {textsMap.productPriceBeforeDiscountSR}
    </span>
    <span data-hook={DataHook.PriceBeforeDiscount} className={s.priceBeforeDiscount}>
      {product.formattedPrice}
    </span>
  </>
);

export const ProductPrice: React.FunctionComponent<IProductPriceProps> = ({
  product,
  textsMap,
  isAddtoCartButtonExists,
  allowFreeProducts,
}) => {
  const isOutOfStock = !product.isInStock;
  const shouldRenderPrices = product.price !== 0 || allowFreeProducts;
  const hasDiscount = product.discount?.value > 0;
  const noRenderWhenDiscountedToZero = !(!allowFreeProducts && product.comparePrice === 0 && hasDiscount);

  if (isOutOfStock && !isAddtoCartButtonExists) {
    return (
      <span data-hook={DataHook.OutOfStock} className={s.outOfStock}>
        {textsMap.productOutOfStockText}
      </span>
    );
  }
  if (shouldRenderPrices && noRenderWhenDiscountedToZero) {
    return (
      <div className={s.prices}>
        {hasDiscount && getPriceBeforeDiscount({textsMap, product})}
        {
          <>
            <span className={a11y.srOnly} data-hook={DataHook.SrPriceToPay}>
              {hasDiscount ? textsMap.productPriceAfterDiscountSR : textsMap.productPriceWhenThereIsNoDiscountSR}
            </span>
            <span data-hook={DataHook.PriceToPay} className={s.priceToPay}>
              {hasDiscount ? product.formattedComparePrice : product.formattedPrice}
            </span>
          </>
        }
      </div>
    );
  }
  return null;
};
